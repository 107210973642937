<template>
  <i class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.49102 12.7642L14.151 18.4142C14.244 18.5079 14.3546 18.5823 14.4764 18.6331C14.5983 18.6838 14.729 18.71 14.861 18.71C14.993 18.71 15.1237 18.6838 15.2456 18.6331C15.3675 18.5823 15.4781 18.5079 15.571 18.4142C15.7573 18.2268 15.8618 17.9733 15.8618 17.7092C15.8618 17.445 15.7573 17.1915 15.571 17.0042L10.621 12.0042L15.571 7.05416C15.7573 6.8668 15.8618 6.61335 15.8618 6.34916C15.8618 6.08498 15.7573 5.83153 15.571 5.64416C15.4784 5.54967 15.368 5.4745 15.2461 5.42301C15.1242 5.37151 14.9933 5.34471 14.861 5.34416C14.7287 5.34471 14.5978 5.37151 14.476 5.42301C14.3541 5.4745 14.2436 5.54967 14.151 5.64416L8.49102 11.2942C8.38952 11.3878 8.30851 11.5015 8.2531 11.628C8.1977 11.7545 8.16909 11.8911 8.16909 12.0292C8.16909 12.1673 8.1977 12.3039 8.2531 12.4304C8.30851 12.5569 8.38952 12.6705 8.49102 12.7642Z"
        fill="white"
      ></path>
    </svg>
  </i>
</template>

<script>
export default {
  name: 'ArrowLeft'
}
</script>
