<template>
  <main class="main">
    <div class="main__inner">
      <div v-if="pageLoading" class="page__loading">
        <LoadingIndicator title="Загрузка" />
      </div>
      <section v-else class="section">
        <h2> Структура ведомства </h2>
        <div v-if="leader" class="minister__container structure-minister__container">
          <div v-if="leader.headImg" class="minister__img">
            <img :src="leader.headImg | image_by_url" :alt="leader.headImg | image_alt" />
          </div>
          <div class="minister__info">
            <div v-if="leader.name || leader.surname || leader.patronymic" class="minister__name">
              {{ leader.surname }} <br />
              {{ leader.name }} <br />
              {{ leader.patronymic }}
            </div>
            <div v-if="leader.post && leader.post.title" class="minister__description">
              {{ leader.post.title }}
            </div>
            <div v-if="leader.user" class="minister__contacts">
              <div v-if="leader.user.phone" class="minister__phone">
                {{ leader.user.phone }}
              </div>
              <a v-if="leader.user.email" :href="'mailto:' + leader.user.email" class="minister__email link__blue">
                {{ leader.user.email }}
              </a>
            </div>
          </div>
        </div>
        <div v-if="leader && leader.childs_user && leader.childs_user.length" class="structure__ministers-block">
          <h3>Заместители</h3>
          <div class="structure__slider-container">
            <div class="structure__slider">
              <swiper ref="deputySwiper" :options="deputySwiperOptions">
                <swiper-slide v-for="(item, index) in leader.childs_user" :key="index">
                  <router-link :to="{ name: 'minister', params: { id: item.id } }" class="structure__minister">
                    <div class="structure__minister-img">
                      <img :src="item.headImg | image($store.state.api)" :alt="item.headImg | image_alt" />
                    </div>
                    <div v-if="item.title" class="structure__minister-name">
                      {{ item.title }}
                    </div>
                    <div v-if="item.post && item.post.title" class="structure__minister-description">
                      {{ item.post.title }}
                    </div>
                  </router-link>
                </swiper-slide>
              </swiper>
              <a @click.prevent="deputySwiper.slidePrev()" class="slider-controls__left">
                <ArrowLeft />
              </a>
              <a @click.prevent="deputySwiper.slideNext()" class="slider-controls__right">
                <ArrowRight />
              </a>
            </div>
            <div v-if="false" class="swiper__pagination deputies-pagination" slot="pagination"></div>
          </div>
        </div>
        <div v-for="(item, index) in personals" :key="index" class="structure__ministers-block">
          <h3 v-if="item.post && item.post.category && item.post.category.title">
            {{ item.post.category.title }}
          </h3>
          <div class="structure__ministers-wrap">
            <router-link :to="{ name: 'minister', params: { id: item.id } }" class="structure__minister structure__minister-main">
              <div class="structure__minister-img">
                <img :src="item.head_img | image($store.state.api)" :alt="item.head_img | image_alt" />
              </div>
              <div class="structure__minister-name">
                {{ item.surname }}
                {{ item.name }}
                {{ item.patronymic }}
              </div>
              <div v-if="item.post && item.post.title" class="structure__minister-description">
                {{ item.post.title }}
              </div>
            </router-link>
            <div
              v-if="
                item.child_personals &&
                filterActives(item.child_personals).length
              "
              class="structure__slider-container"
            >
              <div class="structure__slider">
                <swiper :ref="`swiper${index}`" :options="getOptions(index)">
                  <swiper-slide
                    v-for="(child, i) in filterActives(item.child_personals)"
                    :key="i"
                  >
                    <router-link :to="{ name: 'minister', params: { id: child.id } }" class="structure__minister structure__minister-small">
                      <div class="structure__minister-img">
                        <img :src="child.head_img | image($store.state.api)" :alt="child.head_img | image_alt" />
                      </div>
                      <div class="structure__minister-name">
                        {{ child.surname }} <br />
                        {{ child.name }} <br />
                        {{ child.patronymic }}
                      </div>
                      <div v-if="child.post && child.post.title" class="structure__minister-description">
                        {{ child.post.title }}
                      </div>
                    </router-link>
                  </swiper-slide>
                </swiper>
                <template v-if="item.child_personals.length > getOptions(index).slidesPerView">
                  <a @click.prevent="getSwiper(index).slidePrev()" class="slider-controls__left">
                    <ArrowLeft />
                  </a>
                  <a @click.prevent="getSwiper(index).slideNext()" class="slider-controls__right">
                    <ArrowRight />
                  </a>
                </template>
              </div>
              <div
                v-if="item.child_personals.length > getOptions(index).slidesPerView"
                :class="`dep-pagination${index}`"
                class="swiper__pagination"
                slot="pagination"
              ></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import LoadingIndicator from 'components/LoadingIndicator.vue'
import ArrowLeft from '@/components/svg/ArrowLeft.vue'
import ArrowRight from '@/components/svg/ArrowRight.vue'
import STRUCTURE_PAGE from '@/graphql/structure/StructurePage.graphql'

export default {
  name: 'DepartmentStructurePage',
  data () {
    return {
      pageLoading: true,
      deputySwiperOptions: {
        slidesPerView: 1.3,
        loop: false,
        clickable: true,
        speed: 800,
        breakpoints: {
          300: {
            slidesPerView: 1.5
          },
          420: {
            slidesPerView: 2
          },
          520: {
            slidesPerView: 2.5
          },
          640: {
            slidesPerView: 3
          },
          768: {
            slidesPerView: 3.5
          },
          1150: {
            slidesPerView: 4
          },
          1360: {
            slidesPerView: 4.5
          },
          1500: {
            slidesPerView: 5
          }
        },
        pagination: {
          el: '.deputies-pagination',
          clickable: true
        }
      }
    }
  },
  created () {
    this.$apollo.provider.clients.dept.query({
      query: STRUCTURE_PAGE
    })
      .then(({ data }) => {
        this.pageLoading = false
        if (data) {
          if (data.ActivePersonals) {
            this.$store.state.structure_personals = data.ActivePersonals
          }
          if (data.this_department) {
            this.$store.state.this_department = data.this_department
          }
        }
      })
      .catch((err) => {
        this.pageLoading = false
        console.error(err)
      })
  },
  computed: {
    personals () {
      return this.$store.state.structure_personals
    },
    leader () {
      return this.$store.state.this_department?.leader
    },
    deputySwiper () {
      return this.$refs.deputySwiper.$swiper
    }
  },
  methods: {
    filterActives (list) {
      return list.filter((item) => { return item.status.code === this.$store.state.status.CODE_ACTIVE })
    },
    getSwiper (index) {
      return this.$refs[`swiper${index}`][0].$swiper
    },
    getOptions (index) {
      return {
        slidesPerView: 1.2,
        loop: false,
        clickable: true,
        speed: 800,
        breakpoints: {
          320: {
            slidesPerView: 1.5
          },
          380: {
            slidesPerView: 2
          },
          460: {
            slidesPerView: 2.5
          },
          580: {
            slidesPerView: 3
          },
          640: {
            slidesPerView: 2
          },
          768: {
            slidesPerView: 2.5
          },
          1150: {
            slidesPerView: 3
          },
          1360: {
            slidesPerView: 3.5
          },
          1500: {
            slidesPerView: 4
          }
        },
        pagination: {
          el: `.dep-pagination${index}`,
          clickable: true
        }
      }
    }
  },
  metaInfo () {
    return this.$seo(
      'common',
      'Официальный сайт Главы города Махачкалы',
      '',
      '',
      'Официальный сайт Главы города Махачкалы',
      '',
      ''
    )
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization'
    }
  },
  directives: {
    swiper: directive
  },
  components: {
    LoadingIndicator,
    Swiper,
    SwiperSlide,
    ArrowLeft,
    ArrowRight
  }
}
</script>

<style lang="stylus">
@import "~@/styles/parts/swiper.styl"
@import "~@/styles/parts/minister.styl"

.section__structure {
  display flex
  flex-direction column
  gap 32px
}

.structure {
  &-minister__container {
    padding 32px
    background-color #F9F9FA
    +below(768px) {
      padding 16px
    }
  }

  &__ministers-block:not(:last-child) {
    border-bottom 1px solid var(--color_gray)
  }

  &__ministers-block {
    padding 32px 0
    +below(768px) {
      padding 16px 0
    }
  }

  &__ministers-wrap {
    display flex
    align-items flex-start
    gap 32px
    +below(640px) {
      flex-direction column
      gap 16px
    }

    .structure__slider-container {
      padding-right 32px
      +below(640px) {
        padding-left: 32px;
      }
      +below(540px) {
        padding 0
      }
    }
  }

  &__minister {
    display flex
    flex-direction column
    gap 8px
    padding 16px
    +below(768px) {
      padding 8px
    }

    &-main {
      background-color var(--color_gray_light)
      flex: 0 0 210px
      max-width 210px
      +below(640px) {
        flex: auto
        width 100%
        max-width 100%
      }
    }

    &-small {
      .structure__minister-img {
        width: 100px
        height: 133px
        min-width 100px
      }

      .structure__minister-name {
        color var(--color_dark)
      }
    }

    &-img {
      width: 120px
      height: 160px
      min-width 120px

      img {
        width 100%
        height 100%
        object-fit cover
        object-position center
      }
    }

    &-name {
      font-weight: 500;
      font-size: 1.125em
      line-height: 24px;
      color var(--color_blue_dark)
      +below(768px) {
        font-size 1em
        line-height 20px
      }
    }

    &-description {
      font-size: 0.875em
      line-height: 20px;
      color var(--color_gray_dark)
      +below(768px) {
        font-size 0.75em
        line-height: 16px;
      }
    }
  }

  &__slider {
    position relative

    &-container {
      width 100%

      .swiper__pagination {
        display flex
        align-items center
        justify-content center
        margin: -6px
        margin-top 10px

        .swiper-pagination-bullet {
          width 32px
          height: 2px
          margin 6px
          border-radius 0
          background: var(--color_blue_dark)
          opacity: 0.4;

          &-active {
            opacity 1
          }
        }
      }
    }

    .slider-controls {
      &__left
      &__right {
        width 32px
        height 32px
        position absolute
        top 50%
        //transform translateY(-50%)
        z-index 4
        display flex
        align-items center
        justify-content center
        border none
        +below(540px) {
          display none
        }

        svg {
          width 32px
          height 32px

          path {
            fill var(--color_gray_dark)
            transition 0.3
          }
        }

        &:hover {
          svg path {
            fill var(--color_blue)
          }
        }
      }

      &__left {
        left 0
        transform translate3d(-100%, -50%, 0)
      }

      &__right {
        right 0
        transform translate3d(100%, -50%, 0)
      }
    }
  }
}

</style>
