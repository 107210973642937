<template>
  <i class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5402 11.29L9.88023 5.64004C9.78726 5.54631 9.67666 5.47191 9.5548 5.42115C9.43294 5.37038 9.30224 5.34424 9.17023 5.34424C9.03821 5.34424 8.90751 5.37038 8.78565 5.42115C8.66379 5.47191 8.55319 5.54631 8.46023 5.64004C8.27398 5.8274 8.16943 6.08085 8.16943 6.34504C8.16943 6.60922 8.27398 6.86267 8.46023 7.05004L13.4102 12.05L8.46023 17C8.27398 17.1874 8.16943 17.4409 8.16943 17.705C8.16943 17.9692 8.27398 18.2227 8.46023 18.41C8.55284 18.5045 8.66329 18.5797 8.78516 18.6312C8.90704 18.6827 9.03792 18.7095 9.17023 18.71C9.30253 18.7095 9.43342 18.6827 9.55529 18.6312C9.67717 18.5797 9.78761 18.5045 9.88023 18.41L15.5402 12.76C15.6417 12.6664 15.7227 12.5527 15.7781 12.4262C15.8336 12.2997 15.8622 12.1631 15.8622 12.025C15.8622 11.8869 15.8336 11.7503 15.7781 11.6238C15.7227 11.4973 15.6417 11.3837 15.5402 11.29Z"
        fill="white"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: 'ArrowRight'
}
</script>
